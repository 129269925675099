@tailwind base;
@tailwind components;
@tailwind utilities;


    @font-face {
      font-family: SofiaPro;
      src: url('./assets/fonts/SofiaProRegularAz.otf') ;
      font-weight: 600;
      
    }
    @font-face {
      font-family: SofiaPro;
      src: url('./assets/fonts/SofiaProSemiBoldAz.otf') ;
      font-weight: 600;

    }
    @font-face {
      font-family: SofiaPro;
      src: url('./assets/fonts/SofiaProBoldAz.otf') ;
      font-weight: 700;
    }
    @font-face {
      font-family: BebasNeu;
      src: url('./assets/fonts/BebasNeue-Regular.otf') ;
      font-weight: 400;

    }
